import { DropDownValue, SalesTransferData } from "../types";
import { addTotalInSegmentList, getFormattedRequest } from "../helper";
import { createAction, createSlice } from "@reduxjs/toolkit";

export const actions = {
  addUnlockRequest: "addUnlockRequest",
};

export const incentivePageLists = {
  unlockRequest: "unlockRequest"
};

interface initialStateProps {
  nav: Record<string, any>;
  pages: Record<string, Record<string, any>>;
  modal: {};
  loading: null | number | boolean;
  currentPage: string | null;
  statesList: DropDownValue[] | null;
  divisionList: any;
  reFetchData: boolean,
  addingResponse: boolean,
  fetchingResponse: boolean,
  requestForList: DropDownValue[] | null;
  clearFilterData: boolean,
  salesTransferData: SalesTransferData | null,
  quarterData: any,
  fromHQData: DropDownValue[] | null,
  toHQData: DropDownValue[] | null,
  slmData: DropDownValue[] | null,
  quarterList: any,
  loadingSalesTransferData: boolean,
}

const initialState: initialStateProps = {
  nav: {
    title: null,
    action: {
      title: null,
      type: null,
    },
    search: {
      placeholder: "Search here",
      query: "",
    },
    status: {
      show: false,
      value: true,
    },
  },
  pages: {
    unlockRequest: {
      count: 0,
      pageIndex: 1,
    },
  },
  modal: {
    show: null,
    type: null,
  },
  loading: null,
  currentPage: null,
  statesList: null,
  divisionList: null,
  reFetchData: false,
  addingResponse: false,
  fetchingResponse: false,
  requestForList: null,
  clearFilterData: false,
  salesTransferData: null,
  quarterData: [],
  fromHQData: [],
  toHQData: [],
  slmData: [],
  quarterList: null,
  loadingSalesTransferData: false
};

export const incentiveNavActionHandlerCreator = createAction<any>(
  "INCENTIVE_CONFIGURATION/UPDATE_INCENTIVE_NAV_ACTION_HANDLR"
);

export const incentiveNavActionHandlerCreatorTypeName =
incentiveNavActionHandlerCreator(null).type;

export const incentiveNavSearchQueryCreator = createAction<any>(
  "INCENTIVE_CONFIGURATION/INCENTIVE_NAV_SEARCH_QUERY_CREATOR"
)
export const incentiveNavSearchQueryCreatorTypeName = 
incentiveNavSearchQueryCreator(null).type;

export const incentiveNavToggleCreator = createAction<any>(
  "INCENTIVE_CONFIGURATION/UPDATE_INCENTIVE_NAV_TOGGLE"
)

export const inactiveNavbarDropdownCreator = createAction<any>(
  "INCENTIVE_CONFIGURATION/INCENTIVE_NAV_GET_STATE_DATA"
)
export const inactiveNavbarDropdownCreatorTypeName = 
inactiveNavbarDropdownCreator(null).type;

export const inactiveNavbarDropdownDivisionCreator = createAction<any>(
  "INCENTIVE_CONFIGURATION/INCENTIVE_NAV_GET_DIVISION_DATA"
)
export const inactiveNavbarDropdownDivisionCreatorTypeName = 
inactiveNavbarDropdownDivisionCreator(null).type;

export const incentiveNavToggleCreatorTypeName =
incentiveNavToggleCreator(null).type;

export const incentiveUpdateCurrentPageCreator = createAction<any>(
  "INCENTIVE_CONFIGURATION/UPDATE_UPDATE_CURRENT_PAGE_CREATOR"
)

export const incentiveUpdateCurrentPageCreatorTypeName =
incentiveUpdateCurrentPageCreator(null).type;

// incentive distribution slice
export const fetchYearlyQuartersCreator = createAction('SALES_TRANSFER/FETCH_YEARLY_QUARTERS');
export const fetchYearlyQuartersTypeName = fetchYearlyQuartersCreator().type;

export const fetchFromHQCreator = createAction<any>('SALES_TRANSFER/FETCH_FROM_HQ');
export const fetchFromHQTypeName = fetchFromHQCreator(null).type;

export const fetchToHQCreator = createAction<any>('SALES_TRANSFER/TO_FROM_HQ');
export const fetchToHQTypeName = fetchToHQCreator(null).type;

export const fetchSLMCreator = createAction<any>('SALES_TRANSFER/FETCH_SLM');
export const fetchSLMTypename = fetchSLMCreator(null).type;

export const fetchSalesTransferDataCreator = createAction<any>('SALES_TRANSFER/FETCH_SALES_TRANSFER_DATA');
export const fetchSalesTransferDataTypename = fetchSalesTransferDataCreator(null).type;


const incentiveSlice = createSlice({
  name: "INCENTIVE-CONFIGURATION",
  initialState: initialState,
  reducers: {
    updateNavData: (state, action) => {
      return {
        ...state,
        nav: action.payload,
      };
    },
    updateModalHandler: (state, action) => {
      return {
        ...state,
        modal: action.payload,
      };
    },
    updateLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateTotalRecords: (state, action) => {
      const { listing, ...props }: { listing: string; count: number } =
        action.payload;
      return {
        ...state,
        pages: {
          ...state.pages,
          [listing]: {
            ...state.pages[listing],
            ...props,
          },
        },
      };
    },
    updateNavSearchQuery: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          search: {
            ...state.nav.search,
            query: action.payload,
          },
        },
      };
    },
    updateCurrentPage: (state, action) => {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    updateNavToggle: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          status: {
            ...state.nav.status,
            value: action.payload,
          },
        },
      };
    },
    updateStatesList: (state, action) => {
      return {
        ...state,
        statesList: action.payload,
      };
    },
    updateDivisionList: (state, action) => {
      return {
        ...state,
        divisionList: action.payload,
      }
    },
    setRefetchData: (state, action) => {
      return {
        ...state,
        reFetchData: action.payload
      };
    },
    toggleAddingData: (state, action) => {
      return {
        ...state,
        addingResponse: action.payload
      }
    },
    toggleFetchingData: (state, action) => {
      return {
        ...state,
        fetchingResponse: action.payload
      }
    },
    setRequestForListData: (state, action) => {
      const formattedResponse = getFormattedRequest(action.payload)
      return {
        ...state,
        requestForList: formattedResponse
      }
    },
    toggleClearFilter: (state, action) => {
      return {
        ...state,
        clearFilterData: action.payload
      }
    },
    setSalesTransferData: (state, action) => {
      console.log('action', action.payload)
      return {
        ...state,
        salesTransferData: action.payload
      }
    },
    setQuarterData: (state, action) => {
      return{
        ...state,
        quarterData: action.payload,
      }
    },
    setFromHQData: (state, action) => {
      return {
        ...state,
        fromHQData: action.payload
      }
    },
    setToHQData: (state, action) => {
      return {
        ...state,
        toHQData: action.payload
      }
    },
    setSLMData: (state, action) => {
      return{
        ...state,
        slmData: action.payload,
      }
    },
    setSelectedQuarter: (state, action) => {
      return{
        ...state,
        quarterList: action.payload
      }
    },
    clearSalesTransferState: (state) => {
      return{
        ...state,
        salesTransferData: null,
        quarterData: [],
        fromHQData: [],
        toHQData: [],
        slmData: [],
        quarterList: null
      }
    },
    clearState: (state) => {
      return{
        ...state,
        pages: {
          unlockRequest: {
            count: 0,
            pageIndex: 1,
          },
        },
        modal: {
          show: null,
          type: null,
        },
        loading: null,
        currentPage: null,
        statesList: null,
        divisionList: null,
        reFetchData: false,
        addingResponse: false,
        fetchingResponse: false,
        requestForList: null,
        clearFilterData: false,
      }
    },
    setSalesTransferLoading: (state, action) => {
      state.loadingSalesTransferData = action.payload;
    }
  },
});

export const incentiveActions = incentiveSlice.actions;
export const incentiveReducer = incentiveSlice.reducer;
