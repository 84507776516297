import { createSelector } from "@reduxjs/toolkit";

const partyMasters = (state: any) =>
  state.digitalTwinPartyMasterState.parties;


const getPartyMasters = createSelector(
  [partyMasters],
  (_partyMasters) => _partyMasters
);

export const digitalTwinPartyMasterSelector = {
  getPartyMasters: () => getPartyMasters,
};
