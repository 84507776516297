import { accessGroupMasterStateReducer } from '@app/screens/access-management/pages/access-group-master/redux';
import { appSliceReducer } from './appSlice';
import { approvalWorkflowStateReducer } from '@app/screens/configuration-management/pages/approval-workflow/redux/slice';
import { authTokenReducer } from '@app/screens/generic/Login/redux';
import { columnManagementReducer } from "@app/screens/directory-configuration/pages/column-management/redux";
import { combineReducers } from 'redux';
import { dcrUnlockRequestStateReducer } from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/slice';
import { departmentManagementReducer } from "@app/screens/directory-configuration/pages/department-management/redux";
import { directoryReducer } from "@app/screens/directory-configuration/redux";
import { filterStateReducer } from '@app/components/right-sidebar/redux';
import { hoContactCategoryReducer } from "@app/screens/directory-configuration/pages/ho-contact-category/redux/slice";
import { hoUserReducer } from "@app/screens/directory-configuration/pages/ho-user/redux";
import { incentiveReducer } from "@app/screens/incentive-configuration/redux";
import { planAndMeetStatusStateReducer } from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import { positionManagementStateReducer } from '@app/screens/module-specific-utilities/pages/position-management/redux'
import { routeStateReducer } from '@app/router/redux';
import { salesTransferReducer } from '@app/screens/incentive-configuration/pages/sales-transfer/redux/slice';
import { socialMediaCategorytReducer } from "@app/screens/directory-configuration/pages/social-media-category/redux/slice";
import { socialMediaReducer } from "@app/screens/directory-configuration/pages/social media/redux";
import { tabManagementReducer } from "@app/screens/directory-configuration/pages/tab-management/redux";
import { unlockRequestReducer } from '@app/screens/incentive-configuration/pages/unlock-request/redux';
import { userManagementStateReducer } from '@app/screens/access-management/pages/user-management/redux';
import { moduleSpecificUtilitiesReducer } from '@app/screens/module-specific-utilities/redux';
import { metricCategoryReducer } from '@app/screens/module-specific-utilities/pages/metric-category/redux';
import { digitalTwinPartyMasterReducer } from '@app/screens/module-specific-utilities/pages/digital-twin-party-master/redux';
import { metricPropertiesReducer } from '@app/screens/module-specific-utilities/pages/metric-properties/redux';

export const rootReducer = combineReducers({
  appState: appSliceReducer,
  authState: authTokenReducer,
  routeState: routeStateReducer,
  filterState: filterStateReducer,
  userManagement: userManagementStateReducer,
  accessGroupMaster: accessGroupMasterStateReducer,
  directoryState: directoryReducer,
  tabManagementState: tabManagementReducer,
  columnManagementState: columnManagementReducer,
  hoContactCategoryState: hoContactCategoryReducer,
  socialMediaState: socialMediaReducer,
  hoUserState: hoUserReducer,
  departmentManagementState: departmentManagementReducer,
  socialMediaCategoryState: socialMediaCategorytReducer,
  planAndMeetStatusMaster: planAndMeetStatusStateReducer,
  dcrUnlockRequestMaster: dcrUnlockRequestStateReducer,
  positionManagementMaster: positionManagementStateReducer,
  approvalWorkflowMaster: approvalWorkflowStateReducer,
  incentiveState: incentiveReducer,
  unlockRequestState: unlockRequestReducer,
  salesTransferState: salesTransferReducer,
  moduleSpecificUtilitiesState: moduleSpecificUtilitiesReducer,
  metricCategoryState: metricCategoryReducer,
  digitalTwinPartyMasterState: digitalTwinPartyMasterReducer,
  metricPropertiesState: metricPropertiesReducer,
});
export { } from './appSlice';
