import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Stack,
  Switch
} from '@mui/material';
import {
  moduleSpecificUtilitiesNavActionHandlerCreator,
  moduleSpecificUtilitiesNavSearchQueryCreator,
  moduleSpecificUtilitiesNavToggleCreator,
  moduleSpecificUtilitiesSelector
} from "@app/screens/module-specific-utilities/redux";
import {Search} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {ChangeEvent, useCallback} from 'react';

const useStyles = makeStyles((theme: any) => ({
  search: {
    '& input': {
      padding: '12px 30px 12px 0',
      color: theme.palette.primary.main,
      '::placeholder': theme.palette.primary.main,
    },
  },
}));

const MetricCategoryNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const title = useSelector(moduleSpecificUtilitiesSelector.getNavTitle());
  const action = useSelector(moduleSpecificUtilitiesSelector.getNavAction());
  const search = useSelector(moduleSpecificUtilitiesSelector.getNavSearch());
  const totalCount = useSelector(moduleSpecificUtilitiesSelector.totalRecords, shallowEqual);
  const currentPage = useSelector(moduleSpecificUtilitiesSelector.getCurrentPage, shallowEqual);
  const showToggle = useSelector(moduleSpecificUtilitiesSelector.getShowNavToggle(), shallowEqual);
  const status = useSelector(moduleSpecificUtilitiesSelector.getStatus, shallowEqual);

  const onSearchHandler = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(moduleSpecificUtilitiesNavSearchQueryCreator(event.target.value));
  }, [])

  const onActionHandler = useCallback(() => {
    dispatch(moduleSpecificUtilitiesNavActionHandlerCreator({ show: true, type: action.type }))
  }, [action])

  const onToggleHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(moduleSpecificUtilitiesNavToggleCreator(event.target.checked))
  },[])
  
  return (
    <Grid container justifyContent={'space-between'} sx={{minHeight: 110}}>
      <Grid item xs={8}>
        <Box
          sx={{
            mb: 4,
            mt: 1,
          }}>
          <Typography variant="title" color={'primary.main'}>
            {title}
          </Typography>
        </Box>
        {search ? (
          <TextField
            variant="outlined"
            placeholder={search.placeholder}
            className={classes.search}
            type="search"
            fullWidth
            onChange={onSearchHandler}
            value={search.query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        display={'flex'}
        direction="column"
        justifyContent={'space-between'}>
        <div style={{display: 'flex', alignSelf: 'flex-end', width: 100}}>
          {action ? (
            <Button
              sx={{height: 45, minWidth: 100}}
              variant="contained"
              onClick={onActionHandler}>
              {action.title}
            </Button>
          ) : null}
        </div>
        {showToggle ? (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            alignSelf={'flex-end'}>
            <Typography variant="switch">Inactive</Typography>
            <Switch
              sx={{
                '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(16px)',
                },
                '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                  {
                    opacity: 1,
                  },
              }}
              defaultChecked
              inputProps={{'aria-label': 'ant design'}}
              checked={status === 1}
              onChange={onToggleHandler}
            />
            <Typography variant="switch">Active</Typography>
          </Stack>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default MetricCategoryNavBar;
