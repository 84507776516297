import ColumnManagement from '@app/screens/directory-configuration/pages/column-management';
import ColumnMapping from '@app/screens/directory-configuration/pages/column-mapping';
import DepartmentManagement from '@app/screens/directory-configuration/pages/department-management';
import DirectoryConfiguration from '@app/screens/directory-configuration';
import HOContactCategory from '@app/screens/directory-configuration/pages/ho-contact-category';
import HOUser from '@app/screens/directory-configuration/pages/ho-user';
import IncentiveConfiguration from '@app/screens/incentive-configuration';
import SocialMedia from '@app/screens/directory-configuration/pages/social media';
import SocialMediaCategory from '@app/screens/directory-configuration/pages/social-media-category';
import TabManagement from '@app/screens/directory-configuration/pages/tab-management';
import UnlockRequest from '@app/screens/incentive-configuration/pages/unlock-request';
import SalesTransfer from '@app/screens/incentive-configuration/pages/sales-transfer';
import {lazy} from 'react';

const Home = lazy(() =>
  import('@app/screens/home').then(module => ({default: module.Home})),
);
const Auth = lazy(() =>
  import('@app/screens/generic/Auth').then(module => ({default: module.Auth})),
);
const Login = lazy(() =>
  import('@app/screens/generic/Login').then(module => ({
    default: module.Login,
  })),
);
const AccessManagement = lazy(() =>
  import('@app/screens/access-management').then(module => ({
    default: module.AccessManagement,
  })),
);
const UserManagement = lazy(() =>
  import('@app/screens/access-management/pages/user-management').then(
    module => ({default: module.UserManagement}),
  ),
);
const AccessGroupMaster = lazy(() =>
  import('@app/screens/access-management/pages/access-group-master').then(
    module => ({default: module.AccessGroupMaster}),
  ),
);

const ModuleSpecifiUtilities = lazy(() =>
  import('@app/screens/module-specific-utilities').then(module => ({
    default: module.ModuleSpecifiUtilities,
  })),
);

const PlanAndMeet = lazy(() =>
  import('@app/screens/module-specific-utilities/pages/plan-and-meet').then(
    module => ({default: module.PlanAndMeet}),
  ),
);
const DcrUnlockRequest = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/dcr-unlock-request'
  ).then(module => ({default: module.DcrUnlockRequest})),
);
const PositionManagement = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/position-management'
  ).then(module => ({ default: module.PositionManagement })),
);
const MetricCategory = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/metric-category'
  ).then(module => ({ default: module.default })),
);
const MetricProperties = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/metric-properties'
  ).then(module => ({ default: module.default })),
);
const DigitalTwinPartyMaster = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/digital-twin-party-master'
  ).then(module => ({ default: module.default })),
);
const ApprovalWorkflow = lazy(() =>
  import('@app/screens/configuration-management/pages/approval-workflow').then(
    module => ({ default: module.ApprovalWorkflow }),
  ),
);
const Unauthorized = lazy(() =>
  import('@app/screens/unauthorized').then(module => ({
    default: module.Unauthorized,
  })),
);
const InProgress = lazy(() =>
  import('@app/screens/in-progress').then(module => ({
    default: module.InProgress,
  })),
);

export const RoutesName: any = {
  Login: {
    path: '/login',
    componentName: 'Login',
  },
  Auth: {
    path: '/auth',
    componentName: 'Auth',
  },
  Home: {
    path: '/',
    componentName: 'Home',
  },
  _Home: {
    path: '/home',
    componentName: 'Home',
  },
  Unauthorized: {
    path: '/unauthorized',
    componentName: 'Unauthorized',
  },
  InProgress: {
    path: '/in-progress',
    componentName: 'InProgress',
  },
};

export const RoutesNameComponentMap: any = {
  _Home: <Home />,
  Home: <Home />,
  Login: <Login />,
  Auth: <Auth />,

  AccessManagement: <AccessManagement />,
  UserManagement: <UserManagement />,
  AccessGroupMaster: <AccessGroupMaster />,
  Unauthorized: <Unauthorized />,
  InProgress: <InProgress />,

  DirectoryConfiguration: <DirectoryConfiguration />,
  TabManagement: <TabManagement />,
  ColumnManagement: <ColumnManagement />,
  ColumnMapping: <ColumnMapping />,
  DepartmentManagement: <DepartmentManagement />,
  HOUser: <HOUser />,
  HOContactCategory: <HOContactCategory />,
  SocialMediaCategory: <SocialMediaCategory />,
  SocialMedia: <SocialMedia />,
  ModuleSpecifiUtilities: <ModuleSpecifiUtilities />,
  PlanAndMeet: <PlanAndMeet />,
  DCRUnlockRequest: <DcrUnlockRequest />,
  PositionManagement: <PositionManagement />,
  ApprovalWorkflow: <ApprovalWorkflow />,
  MetricCategory: <MetricCategory />,
  MetricProperties: <MetricProperties/>,
  DigitalTwinPartyMaster: <DigitalTwinPartyMaster />,

  IncentiveConfiguration: <IncentiveConfiguration />,
  UnlockRequest: <UnlockRequest />,
  SalesTransfer: <SalesTransfer />,
};
