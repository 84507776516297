import { TableCell, TableRow } from '@mui/material';

import DialogBox from '@app/components/dialogBox';
import React from 'react'
import { incentiveSelector } from '@app/screens/incentive-configuration/redux/selectors';
import { useSelector } from 'react-redux';

function ExpandedCell(props: {
  row: any;
  onUnMount: any;
  unmount: any
}) {
  const { row } = props;
  
  const labelRef = React.useRef<HTMLInputElement>(null);
	const [open, setOpen] = React.useState(false);
	const [header, setHeader] = React.useState('');
	const [message, setMessage] = React.useState('')
	const salesTransferDataState = useSelector(incentiveSelector.getSalesTransferData());

	const openModal = React.useCallback((e: any, info: string) => {
		const clientWidth = labelRef?.current?.clientWidth || 0
		const scrollWidth = labelRef?.current?.scrollWidth || 0
		if (salesTransferDataState.isLocked && clientWidth < scrollWidth) {
			setHeader('Remarks');
			setMessage(info);
			setOpen(true)
		}
	}, [salesTransferDataState.isLocked, labelRef])

	const closeModal = React.useCallback(() => {
		setOpen(false);
		setMessage('');
		setHeader('');
		props.onUnMount(!props.unmount);
	}, [props.unmount])

  return (
    <><DialogBox open={open} handleClose={closeModal} body={message} header={header} /><TableRow key={row.id} style={{ backgroundColor: '#fff', marginBottom: 4 }}>
      <TableCell padding="checkbox" />
      <TableCell style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 12 }}>{row.name}</TableCell>
      <TableCell style={{ fontWeight: 700 }}>{Number(row.incentivePercent).toFixed(2)}</TableCell>
      <TableCell>
        <div style={{ backgroundColor: "#F2F6F7", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 600 }}>
          {Number(row.month1).toFixed(0)}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ backgroundColor: "#F2F6F7", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 600 }}>
          {Number(row.month2).toFixed(0)}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ backgroundColor: "#F2F6F7", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 600 }}>
          {Number(row.month3).toFixed(0)}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ backgroundColor: "#F2F6F7", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 600 }}>
          {(Number(row.month1) + Number(row.month2) + Number(row.month3)).toFixed(0)}
        </div></TableCell>
      <TableCell>
        <div style={{ backgroundColor: "#F2F6F7", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 600 }}>
          {Number(row.incentiveAmount).toFixed(2)}
        </div>
      </TableCell>
      <TableCell>
        <p ref={labelRef} style={{
          backgroundColor: "#F2F6F7", borderRadius: 5, height: 26, width: 200, textAlign: 'left', padding: 5, flexDirection: 'column', justifyContent: 'center', fontWeight: 600, textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          cursor: Boolean(row.remarks.length) ? 'pointer' : 'auto',
        }} onClick={(e) => openModal(e, row.remarks)}>
          {row.remarks.trim()}
        </p>
      </TableCell>			</TableRow></>
  )
}

export default ExpandedCell