export const Styles = {
  inputContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputCell: {
    margin: '0 auto',
    width: '100%',
    marginBottom: 10
  },
  labelTypography: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: '12.7px',
    color: '#1c1939',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '60%',
    margin: '0 auto',
  }
}