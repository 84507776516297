import {Provider} from 'react-redux';
import {getStore} from '@app/store';
import {AppRouter} from '@app/router/AppRouter';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import {ErrorBoundary} from './ErrorBoundary';

export const store = getStore();
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    title: {main: string};
    white: {main: string};
  }
  interface PaletteOptions {
    title?: {main: string};
    white?: {main: string};
  }
}
declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    backgroundColors: {leftSidebar: string};
  }
  interface Theme {
    backgroundColors: {leftSidebar: string};
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    error: React.CSSProperties;
    switch: React.CSSProperties;
    popupHeading: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    error?: React.CSSProperties;
    switch?: React.CSSProperties;
    popupHeading?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    error: true;
    switch: true;
    popupHeading: true;
  }
}

const palette = createTheme({
  backgroundColors: {
    leftSidebar: '#f5f8f9',
  },
  palette: {
    title: {main: '#322b7c'},
    white: {
      main: '#ffffff',
    },
    primary: {
      light: '#99322b7c',
      main: '#322b7c',
      dark: '#1c1939',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
    },
    grey: {
      A400: '#3a1c1939',
      '100': '#acacac',
      '700': '#777777',
      '50': '#eeeeee',
    },
    success: {
      main: '#34b53a',
    },
    error: {
      main: '#f00',
    },
  },
});

export const themeOptions = createTheme(palette, {
  typography: {
    fontFamily: ['Poppins'].join(','),
    fontSize: 13,
    title: {
      fontWeight: '600',
      fontSize: 18,
      fontStyle: 'normal',
    },
    subtitle1: {
      fontWeight: '600',
      textTransform: 'uppercase',
      fontSize: 12.7,
      color: palette.palette.primary.dark,
    },
    error: {
      color: palette.palette.error.main,
      fontSize: 12,
      fontWeight: '500',
    },
    switch: {
      fontSize: 20,
      color: palette.palette.primary.main,
      fontWeight: '500',
    },
    popupHeading: {
      fontSize: 22.7,
      color: palette.palette.common.black,
      fontWeight: '500',
    },
    leftSideBar: {
      color: '#534e91',
    },
    rightSideBar: {
      color: '#534e91',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 800,
          color: '#322b7c',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          '::placeholder': palette.palette.grey.A400,
          fontFamily: ['Poppins'].join(','),
          fontSize: 12.7,
          fontWeight: 'normal',
        },
        inputRoot: {
          padding: '5.5px 14px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        },
        switchBase: {
          padding: '9px 0 0 1px',
        },
        thumb: {
          width: 18,
          height: 18,
          backgroundColor: palette.palette.grey[100],
        },
        track: {
          height: 20,
          width: 36,
          borderRadius: 20,
          backgroundColor: palette.palette.primary.main,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
          fontFamily: ['Poppins'].join(','),
          wordBreak: 'break-word',
        },
      },
      variants: [
        {
          props: {variant: 'head'},
          style: {
            fontWeight: '600',
            textTransform: 'uppercase',
            fontSize: 12.7,
            color: palette.palette.primary.dark,
            whiteSpace: 'nowrap',
          },
        },
        {
          props: {variant: 'body'},
          style: {
            fontWeight: '500',
            fontSize: 12,
            color: palette.palette.primary.main,
          },
        },
        {
          props: {variant: 'body', color: 'active'},
          style: {
            color: palette.palette.success.main,
          },
        },
        {
          props: {variant: 'body', color: 'inactive'},
          style: {
            color: palette.palette.grey[100],
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ['Poppins'].join(','),
          textTransform: 'Capitalize',
          fontSize: 12.7,
          padding: '11px 16px',
          minWidth: 165,
          fontWeight: '600',
          cursor: 'pointer',
          fontStyle: 'normal',
          letterSpacing: 0,
          textAlign: 'center',
        },
        containedPrimary: {
          backgroundColor: palette.palette.primary.main,
          color: palette.palette.common.white,
        },
        outlinedPrimary: {
          color: palette.palette.primary.main,
          outline: `2px solid ${palette.palette.primary.main}`,
          border: 0,
          ':hover': {
            border: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      variants: [
        {
          props: {variant: 'standard'},
          style: {
            fontSize: 12.7,
            fontWeight: '600',
            fontFamily: ['Poppins'].join(','),
            color: palette.palette.primary.dark,
            textTransform: 'capitalize',
            marginBottom: 10,
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '::placeholder': palette.palette.grey.A400,
          fontFamily: ['Poppins'].join(','),
          fontSize: 12.7,
          fontWeight: 'normal',
          padding: '12px 20px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          alignItems: 'flex-start',
        },
        label: {
          fontWeight: '600',
          fontSize: 12.7,
          color: palette.palette.primary.dark,
          marginBottom: 8,
          textTransform: 'capitalize',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          width: '100%',
          height: '100%',
          backgroundColor: palette.palette.primary.main,
          borderRadius: '100px',
          top: '0.2px',
          zIndex: 1,
          marginLeft: '1px',
          marginRight: '1px',
        },
        root: {
          minHeight: 'unset',
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: palette.palette.primary.dark,
          zIndex: 3,
          padding: "7px 20px",
          minWidth: 'unset',
          minHeight: 'unset',
          '&.Mui-selected': {
            color: palette.palette.common.white,
          }
        },
      },
    },
  },
});

const theme = responsiveFontSizes(themeOptions);

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
