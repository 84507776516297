import { createSelector } from "@reduxjs/toolkit";

const navData = (state: any) => state.incentiveState.nav;
const modalData = (state: any) => state.incentiveState.modal;
const loadingData = (state: any) => state.incentiveState.loading;
const searchData = (state: any) => state.incentiveState.nav.search;
const pagesData = (state: any) => state.incentiveState.pages;
const currentPage = (state: any) => state.incentiveState.currentPage;
const stateList = (state: any) => state.incentiveState.statesList;
const divisionList = (state: any) => state.incentiveState.divisionList;
const reFetchData = (state: any) => state.incentiveState.reFetchData;
const addingResponse = (state: any) => state.incentiveState.addingResponse;
const fetchingResponse = (state: any) => state.incentiveState.fetchingResponse;
const getRequestList = (state: any) => state.incentiveState.requestForList
const clearFilterData = (state: any) => state.incentiveState.clearFilterData

// incentive distribution
const quarterData = (state: any) => state.incentiveState.quarterData
const fromHQData = (state: any) => state.incentiveState.fromHQData
const toHQData = (state: any) => state.incentiveState.toHQData
const slmData = (state: any) => state.incentiveState.slmData
const salesTransferData = (state: any) => state.incentiveState.salesTransferData
const selectedQuarterListData = (state: any) => state.incentiveState.quarterList
const loadingSalesTransferData = (state: any) => state.incentiveState.loadingSalesTransferData

const searchQuery = createSelector([searchData], (_searchData) =>
  _searchData && _searchData.query ? _searchData.query : ""
);
const getStatus = createSelector([navData], (nav) =>
  nav?.status?.value ? 1 : 0
);
const getShowNavToggle = createSelector([navData], (nav) => nav?.status?.show);
const getNavTitle = createSelector([navData], (nav) => nav.title);
const getNavAction = createSelector([navData], (nav) => nav.action);
const getNavSearch = createSelector([navData], (nav) => nav.search);
const getincentiveModal = createSelector([modalData], (modal) => modal);
const getLoading = createSelector([loadingData], (loading) => loading);
const getReFetchData = createSelector([reFetchData], (reFetchData) => reFetchData);
const getCurrentPage = createSelector(
  [currentPage],
  (_currentPage) => _currentPage
);
const totalRecords = createSelector(
  pagesData,
  currentPage,
  (_pages, _page) => _pages[_page]?.count || 0
);
const pageIndex = createSelector(
  pagesData,
  currentPage,
  (_pages, _page) => _pages[_page]?.pageIndex || 1
);
const getStateList = createSelector([stateList], (stateList) => stateList);
const getDivisionList = createSelector([divisionList], (divisionList) => divisionList);
const getFROMHQList = createSelector([fromHQData], (fromHQData) => fromHQData);
const getToHQList = createSelector([toHQData], (toHQData) => toHQData);
const getSLMData = createSelector([slmData], (slmData) => slmData);
const getSalesTransferData = createSelector( [salesTransferData],(salesTransferData) => salesTransferData)
const getselectedQuarterListData = createSelector( [selectedQuarterListData], (selectedQuarterListData) => selectedQuarterListData)
const getLoadingSalesTransferData = createSelector( [loadingSalesTransferData], (loadingSalesTransferData) => loadingSalesTransferData)

// incentive distribution

const getQuartetList = createSelector([quarterData], (quarterData) => quarterData);
const getFromHQList = createSelector

export const incentiveSelector = {
  getNavTitle: () => getNavTitle,
  getNavAction: () => getNavAction,
  getincentiveModal: () => getincentiveModal,
  getLoading: () => getLoading,
  getNavSearch: () => getNavSearch,
  searchQuery,
  getStatus,
  totalRecords,
  pageIndex,
  getShowNavToggle: () => getShowNavToggle,
  getCurrentPage,
  getStateList: () => getStateList,
  getDivisionList: () => getDivisionList,
  getReFetchData: () => getReFetchData,
  addingResponse: () => addingResponse,
  fetchingResponse: () => fetchingResponse,
  getRequestList: () => getRequestList,
  clearFilterData: () => clearFilterData,
  getQuartetList: () => getQuartetList,
  getFROMHQList: () => getFROMHQList,
  getToHQList: () => getToHQList,
  getSLMData: () => getSLMData,
  getSalesTransferData: () => getSalesTransferData,
  getselectedQuarterListData: () => getselectedQuarterListData, 
  getLoadingSalesTransferData: () => getLoadingSalesTransferData,
};
