import React, {useCallback, useEffect, useState} from 'react';
import {
  incentiveActions,
} from '../../redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {Box, Button} from '@mui/material';
import DatePickerComponent from '@app/components/date-picker/DatePickerComponent';
import Dropdown from '@app/components/dropdown';
import {Styles} from './stlye';
import dayjs from 'dayjs';
import {incentiveSelector} from '../../redux/selectors';
import {unlockRequestCreator} from '../../pages/unlock-request/redux';

enum FilterType {
  division = 'division',
  state = 'state',
  unlockTillDate = 'unlockTillDate',
  requestFor = 'requestFor',
}

function FilterHeader(props: {changeFilter: Function; clearFilter: Function}) {
  const [date, setDate] = useState<Date | null>(null);
  const [state, setState] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [division, setDivision] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [request, setRequestFor] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [selectedFilter, setSelectedFilter] = useState({
    unlockTillDate: null,
    stateId: null,
    divisionId: null,
    requestedFor: null,
  });

  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const stateList = useSelector(incentiveSelector.getStateList());
  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const requestList = useSelector(incentiveSelector.getRequestList());
  const clearFilterData = useSelector(incentiveSelector.clearFilterData());

  useEffect(() => {
    if (clearFilterData) {
      resetData();
      props.clearFilter();
    }
  }, [clearFilterData]);

  const dispatch = useDispatch();

  const resetData = () => {
    setDate(null);
    setState(undefined);
    setRequestFor(undefined);
    setDivision(undefined);
    dispatch(incentiveActions.toggleClearFilter(false));
  };

  const filterHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      const dataParam = {
        pageIndex: 1,
        isActive: isActive == 1,
        divisionId: division?.value,
        stateId: state?.value,
        requestedFor: request?.label,
        unlockTillDate: date ? dayjs(date).format() : null,
      };
      dispatch(unlockRequestCreator(dataParam));
    },
    [division, state, request, date, isActive],
  );

  const selectfilterHandler = useCallback(
    (val: any, typeOfFilter: FilterType) => {
      let value: any;
      switch (typeOfFilter) {
        case FilterType.division:
          value = {
            ...selectedFilter,
            divisionId: val?.value,
          };
          break;
        case FilterType.state:
          value = {
            ...selectedFilter,
            stateId: val?.value,
          };
          break;
        case FilterType.unlockTillDate:
          value = {
            ...selectedFilter,
            unlockTillDate: val,
          };
          break;
        case FilterType.requestFor:
          value = {
            ...selectedFilter,
            requestedFor: val?.label,
          };
          break;
      }
      setSelectedFilter(value);
    },
    [selectedFilter, props],
  );
  return (
    <div style={Styles.filterHeaderBody}>
      <div style={Styles.filterBox}>
        <Box style={Styles.inputCell} data-testid={'selectDiv-dropdown'}>
          <Dropdown
            onChange={(val: any) => {
              selectfilterHandler(val, FilterType.division);
              setDivision(val);
            }}
            value={division}
            dropdownList={divisionList || []}
            placeholder="Select Division"
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'selectState-dropdown'}>
          <Dropdown
            onChange={(val: any) => {
              selectfilterHandler(val, FilterType.state);
              setState(val);
            }}
            value={state}
            dropdownList={stateList}
            placeholder="Select State"
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'selectDate-picker'}>
          <DatePickerComponent
            disablePast={false}
            onError={() => setDate(null)}
            value={date}
            placeholder="Select Date"
            onChange={(val: Date) => {
              if (val) {
                selectfilterHandler(val, FilterType.unlockTillDate);
                setDate(new Date(val));
              } else {
                props.changeFilter({
                  date: null,
                });
                setDate(null);
              }
            }}
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'selectRequest-dropdown'}>
          <Dropdown
            onChange={(val: any) => {
              selectfilterHandler(val, FilterType.requestFor);
              setRequestFor(val);
            }}
            value={request}
            dropdownList={requestList}
            placeholder="Request For"
          />
        </Box>

        <Button
          data-testid="clickGo-icon"
          variant="contained"
          onClick={filterHandler}
          size="small"
          style={{
            fontFamily: ['Poppins'].join(','),
            fontSize: 12.7,
            padding: '11px',
            // fontWeight: '600',
            cursor: 'pointer',
            // fontStyle: 'normal',
            letterSpacing: 0,
            textAlign: 'center',
            backgroundColor: '#322b7c',
            color: 'white',
            border: 0,
            borderRadius: 10,
            minWidth: 50,
          }}>
          Go
        </Button>
      </div>
    </div>
  );
}

export default React.memo(FilterHeader);
