import { useDispatch, useSelector } from "react-redux";

import FilterHeader from "./FilterHeader";
import SalesTranferTable from "./SalesTransferTable";
import {
  incentiveActions,
} from '../../redux';
import { incentiveSelector } from '../../redux/selectors';
import { navbarComponentName } from "@app/router/redux/routeHandler";
import { routeStateActions } from "@app/router/redux";
import { useEffect } from "react";

const SalesTransfer = () => {
  const dispatch = useDispatch();
  const salesTransferData = useSelector(incentiveSelector.getSalesTransferData());
  const getLoadingSalesTransferData = useSelector(incentiveSelector.getLoadingSalesTransferData());

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.incentiveConfiguration,
      ),
    );

    dispatch(
      incentiveActions.updateNavData({
        title: 'Sales Transfer',
        action: null,
      }),
    );

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(incentiveActions.clearSalesTransferState())
    };
  }, []);


  return (
    <div>
      <FilterHeader />
     {salesTransferData && <SalesTranferTable loading={getLoadingSalesTransferData} showDisclaimer={salesTransferData?.showDisclaimer} />}
    </div>
  );
};

export default SalesTransfer;
