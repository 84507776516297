import { API_PATH, TOAST_MESSAGES } from "@app/common/constants";
import { routeStateActions } from "@app/router/redux";
import { navbarComponentName } from "@app/router/redux/routeHandler";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TableCell,
  TableRow,
  Grid,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { directoryActions, directorySelector } from "../../redux";
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from "../../redux/slice";
import {
  columnManagementActions,
  columnManagemnetSelector,
  fetchTabListCreator,
} from "../column-management/redux";
import { DirectoryColumnsProps } from "../column-management/types";
import TableList from "../../components/TableList";

const tablecolumns = ["Column Name", "Order No.", "Action"];

const ColumnMapping = () => {
  const [initialColumns, setInitialColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isColumnsFetching, setIsColumnsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();

  const tabList = useSelector(
    columnManagemnetSelector.getTabList(),
    shallowEqual
  );
  const currentTabId = useSelector(
    columnManagemnetSelector.getCurrentTabId,
    shallowEqual
  );
  const loading = useSelector(directorySelector.getLoading(), shallowEqual);

  const isTabListFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.fetchTabList &&
      (!tabList || tabList.length === 0),
    [loading, tabList]
  );

  const isEmpty = useMemo(
    () =>
      !isColumnsFetching && currentTabId && (!columns || columns.length === 0),
    [isColumnsFetching, columns, currentTabId]
  );

  const fetchTabColumns = async () => {
    setIsColumnsFetching(true);
    try {
      const response = await NetworkService.get(
        API_PATH.directoryConfiguration.fetchTabColumns,
        { tabId: currentTabId },
        {}
      );
      setIsColumnsFetching(false);
      setInitialColumns(response.data);
      setColumns(response.data);
    } catch (error) {
      setIsColumnsFetching(false);
    }
  };

  const onFormUpdateHandler = ({
    isActive,
    index,
  }: {
    isActive: number;
    index: number;
  }) => {
    const updatedColumns: any = [...columns]?.map((column: object, _index) => {
      if (index === _index) {
        return {
          ...column,
          isActive,
        };
      }
      return column;
    });
    setColumns(updatedColumns);
  };

  const onColumnsUpdateHandler = useCallback(async()=> {
    const params = {
      tabId: currentTabId,
      columnsList: columns
        .filter(({ id, isActive }) =>
          initialColumns.findIndex(
            (val: any) => val.id === id && val.isActive === isActive
          ) === -1
        )
        ?.map((column: DirectoryColumnsProps) => ({
          id: column.id,
          isActive: column.isActive,
        })),
    };
    setIsUpdating(true);
    try {
      await NetworkService.post(
        API_PATH.directoryConfiguration.updateTabColumns,
        params,
        {}
      );
      await fetchTabColumns();
      ToasterService.showToaster(
        TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
        ToastType.SUCCESS
      );
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
    }
  },[currentTabId,columns]);

  useEffect(() => {
    if (currentTabId) {
      fetchTabColumns();
    } else {
      setInitialColumns([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabId]);

  useEffect(() => {
    /**** change haeder */
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.DirectoryConfiguration
      )
    );
    dispatch(
      directoryActions.updateNavData({
        title: "Column Mapping",
      })
    );
    dispatch(
      directoryUpdateCurrentPageCreator(directoryPageLists.columnMapping)
    );

    /**** fetch tab list */
    dispatch(fetchTabListCreator({}));

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(columnManagementActions.updateCurrentTabId(null));
    };
  }, []);

  const onChangeColumnHandler = useCallback((event)=>{
    const index = parseInt(event.target.name);
    onFormUpdateHandler({
      isActive: event.target.checked ? 1 : 0,
      index,
    })
  },[onFormUpdateHandler])
  
  const getColumns = useCallback(() => {
    setColumns(initialColumns)
  },[initialColumns])


  if (isTabListFetching) {
    return (
      <Box justifyContent={"center"} display="flex" sx={{ mt: 2 }}>
        <CircularProgress sx={{ color: "primary.main" }} disableShrink />
      </Box>
    );
  }


  return (
    <>
      {isUpdating ? (
        <Backdrop sx={{ zIndex: 9999 }} open>
          <CircularProgress sx={{ color: "primary.main" }} disableShrink />
        </Backdrop>
      ) : null}
      <TableList
        columns={tablecolumns}
        isLoading={isColumnsFetching}
        maxHeight={350}
        isEmpty={isEmpty}
      >
        {columns && columns.length > 0
          ? columns?.map((column: DirectoryColumnsProps, index: any) => (
              <>
                <TableRow>
                  <TableCell>{column.name}</TableCell>
                  <TableCell align="right" sx={{ width: "10%" }}>
                    {column.orderNo}
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox
                      name={index.toString()}
                      onChange={onChangeColumnHandler}
                      checked={column.isActive === 1}
                    />
                  </TableCell>
                </TableRow>
                <Divider
                  component={TableRow}
                  variant="fullWidth"
                  sx={{ height: 5, border: 0 }}
                />
              </>
            ))
          : null}
      </TableList>
      {!isEmpty && currentTabId ? (
        <Grid container justifyContent={"flex-end"} sx={{ mt: 3, pr: 2 }}>
          <Grid item mr={2}>
            <Button
              variant="outlined"
              data-current={JSON.stringify(initialColumns)}
              onClick={getColumns}
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={onColumnsUpdateHandler}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default ColumnMapping;
