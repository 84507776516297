import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import env from '../../env.json';
import {store} from '../../App';

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: env.AZURE_APPINSIGHT_CONNECTION_STRING,
  },
});

export const trackInsightPageView = (screenName: any, params?: any) => {
  const userDetails: any = store?.getState()?.appState?.userInfo;

  appInsights.trackPageView({
    name: screenName,
    properties: {
      name: userDetails?.name,
      staffPositionId: userDetails?.staffPositionId,
      userId: userDetails?.userId,
      environment: `Admin ${env.ENVIRONMENT}`,
      ...params,
    },
  });
};

export const trackInsightEvent = (eventName: any, params?: any) => {
  const userDetails: any = store?.getState()?.appState?.userInfo;

  appInsights.trackEvent({
    name: eventName,
    properties: {
      name: userDetails?.name,
      staffPositionId: userDetails?.staffPositionId,
      userId: userDetails?.userId,
      environment: `Admin ${env.ENVIRONMENT}`,
      ...params,
    },
  });
};

export const trackInsightTrace = (message: any, params?: any) => {
  const userDetails: any = store?.getState()?.appState?.userInfo;

  appInsights.trackTrace({
    message: message,
    properties: {
      name: userDetails?.name,
      staffPositionId: userDetails?.staffPositionId,
      userId: userDetails?.userId,
      environment: `Admin ${env.ENVIRONMENT}`,
      ...params,
    },
  });
};

export const trackInsightException = (error: any, params?: any) => {
  const userDetails: any = store?.getState()?.appState?.userInfo;

  appInsights.trackException({
    exception: error,
    properties: {
      name: userDetails?.name,
      staffPositionId: userDetails?.staffPositionId,
      userId: userDetails?.userId,
      environment: `Admin ${env.ENVIRONMENT}`,
      ...params,
    },
  });
};