import * as React from 'react';

import { Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { MonthType, SegmentList } from '@app/screens/incentive-configuration/types';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandedCell from './ExpandedCell';
import If from '@app/components/If';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getGrandTotal } from '@app/screens/incentive-configuration/helper';
import { incentiveSelector } from '@app/screens/incentive-configuration/redux/selectors';
import { styles } from './style';
import { useSelector } from 'react-redux';

export default function SalesTranferTable(props: {
	loading: boolean
	showDisclaimer: boolean
}) {
	const [unmount, setUnmount] = React.useState(true);

	const salesTransferDataState = useSelector(incentiveSelector.getSalesTransferData());
	const quarterList = useSelector(incentiveSelector.getselectedQuarterListData());

	return (
		<Box sx={{ p: 2 }}>
			{
				props.loading ? <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '60vh' }}>
					<CircularProgress sx={{ color: 'primary.main', margin: '0 auto' }} disableShrink />
				</Box> : <> <TableContainer sx={{ maxHeight: '60vh' }}>
					<Table stickyHeader aria-label="sticky table" size="medium">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox" />
							<TableCell style={{ ...styles.groupHeder, paddingLeft: 13 }}>Group</TableCell>
							<TableCell align="left" style={styles.tableHeader}>INC%</TableCell>
							{quarterList?.month?.map((month: any, index: any) => {
								const index_key = index.toString();
								return (
									<TableCell key={index_key} align="left" style={styles.tableHeader}>{month.name}</TableCell>
								);
							})}
							<TableCell align="left" style={{
								...styles.totalHeader, whiteSpace: 'normal',
								wordWrap: 'break-word',
							}}>Total Sale<br />{quarterList.name}</TableCell>
							<TableCell align="left" style={styles.tableHeader}>Incentive amount</TableCell>
							<TableCell align="left" style={styles.remarkHeader}>Remark</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{salesTransferDataState?.segmentList?.map((row: SegmentList) => (
							<ExpandableTableRow key={row.id} expandComponent={row.subSegment} unmount={unmount} onUnMount={(val: boolean) => {
								setUnmount(val)
							}}>
								<TableCell component="th" scope="row" style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 12 }}>{row.name}</TableCell>
								<TableCell style={{ fontWeight: 700 }}></TableCell>
								<TableCell>
									<div style={{ backgroundColor: "#e9e9e9", borderRadius: 5, height: 26, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, }}>
										{Number(row.month1).toFixed(0)}
									</div>
								</TableCell>
								<TableCell>
									<div style={{ backgroundColor: "#e9e9e9", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, }}>
										{Number(row.month2).toFixed(0)}
									</div>
								</TableCell>
								<TableCell>
									<div style={{ backgroundColor: "#e9e9e9", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, }}>
										{Number(row.month3).toFixed(0)}
									</div>
								</TableCell>
								<TableCell>
									<div style={{ backgroundColor: "#e9e9e9", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, }}>
										{(Number(row.month1) + Number(row.month2) + Number(row.month3)).toFixed(0)}
									</div></TableCell>
								<TableCell>
									<div style={{ backgroundColor: "#e9e9e9", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, }}>
										{Number(row.incentiveAmount).toFixed(2)}
									</div>
								</TableCell>
								<TableCell>
									{row.subSegment.length === 0 && <div style={{ backgroundColor: "#e9e9e9", borderRadius: 5, width: '60%', height: 26, minWidth: 70, textAlign: 'left', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700 }}>
										{row.remarks}
									</div>}
								</TableCell>
							</ExpandableTableRow>
						))}
					</TableBody>
					<TableFooter style={{left: 0,bottom: 0, zIndex: 2,position: 'sticky'}}>
						<TableRow style={{ backgroundColor: '#2fb4ff' }}>
							<TableCell padding="checkbox" />
							<TableCell colSpan={2} style={{ color: '#ffffff', fontSize: 12, fontWeight: 700 }}>Grand total</TableCell>
							<TableCell align="left">
								<div style={{ backgroundColor: "#94d8ff", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, color: '#322b7c' }}>
									{getGrandTotal(salesTransferDataState?.segmentList, MonthType.month1).toFixed(0)}
								</div>
							</TableCell>
							<TableCell align="left">
								<div style={{ backgroundColor: "#94d8ff", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, color: '#322b7c' }}>
									{getGrandTotal(salesTransferDataState?.segmentList, MonthType.month2).toFixed(0)}
								</div>
							</TableCell>
							<TableCell align="left">
								<div style={{ backgroundColor: "#94d8ff", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, color: '#322b7c' }}>
									{getGrandTotal(salesTransferDataState?.segmentList, MonthType.month3).toFixed(0)}
								</div>
							</TableCell>
							<TableCell align="left">
								<div style={{ backgroundColor: "#94d8ff", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, color: '#322b7c' }}>
									{getGrandTotal(salesTransferDataState?.segmentList, MonthType.total).toFixed(0)}
								</div>
							</TableCell>
							<TableCell align="left">
								<div style={{ backgroundColor: "#94d8ff", borderRadius: 5, height: 26, minWidth: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, color: '#322b7c' }}>
									{getGrandTotal(salesTransferDataState?.segmentList, MonthType.incentive).toFixed(2)}
								</div>
							</TableCell>
							<TableCell align="left">
								<div style={{ borderRadius: 5, height: 26, minWidth: 70, width: 70, textAlign: 'right', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 700, color: '#322b7c' }}>
								</div>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
				</TableContainer>
					{props.showDisclaimer && <div style={{ backgroundColor: '#FED1D0', marginTop: 20, width: '100%', height: 50, borderRadius: 5, border: '1px solid #FED1D0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
						<div>
							<span style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: 12 }}>
								Disclaimer -
							</span>
							<span style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 12 }}>
								{' '} Data might have been adjusted by the HO team during calculation
							</span>
						</div>
					</div>}
				</>
			}
		</Box>
	);
}


const ExpandableTableRow = (props: any) => {
	const [isExpanded, setIsExpanded] = React.useState(false);

	const getChild = React.useMemo(() => {
		return (
			<React.Fragment>
				{
					props.expandComponent.map((row: Omit<SegmentList, "subSegment">,index: any) => {
						const index_key = index.toString();
						return (
							<ExpandedCell key={index_key} row={row} onUnMount={props.onUnMount} unmount={props.unmount} />
						)
					}
					)
				}
			</React.Fragment>
		)
	}, [open, props])
	return (
		<>
			<TableRow {...props.otherProps} style={{ backgroundColor: 'rgba(213, 226, 231, 0.32)' }}>
				<TableCell padding="checkbox">
					{props?.expandComponent?.length > 0 ?
						<IconButton onClick={() => setIsExpanded(!isExpanded)}>
							<If condition={isExpanded}>
								<RemoveCircleOutlineIcon style={{ color: '#322b7c' }} />
							</If>
							<If condition={!isExpanded}>
								<AddCircleOutlineIcon style={{ color: '#322b7c' }} />
							</If>
						</IconButton>
						: null}
				</TableCell>
				{props.children}
			</TableRow>
			{isExpanded && getChild}
		</>
	);
};