export enum REQUEST_FOR_ENUM{
  saleTransfer = "Sale Transfer",
  incentiveDistribution = "Incentive Distribution"
}

export enum MonthType {
  month1 = "month1",
  month2 = "month2",
  month3 = "month3",
  total = "total",
  incentive = "incentiveAmount"
}

export interface DropDownValue {
  label: string;
  value: number
}

export interface ModalExistingDataType{
  id: number;
  unlockTillDate: Date | null;
  reason: string;
  state: DropDownValue;
  division: DropDownValue;
  request: DropDownValue;
  isActive: boolean;
}

export interface SegmentList {
  id: number,
  incentiveAmount: number,
  incentivePercent: number,
  isActive: boolean,
  isIncentiveAmount: boolean,
  month1: number,
  month2: number,
  month3: number,
  name: string,
  remarks: string,
  subSegment: Omit<SegmentList, "subSegment">[]
  total?: number
}


export interface SalesTransferData{
  fromHQId: number;
  isLocked: boolean;
  quarterId: number,
  segmentList: SegmentList[],
  showDisclaimer: boolean,
  staffPositionId: number,
  toHQId: number,
}