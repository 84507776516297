import { call, put, takeLatest } from "redux-saga/effects";
import { fetchFromHQTypeName, fetchSLMTypename, fetchSalesTransferDataTypename, fetchToHQTypeName, fetchYearlyQuartersTypeName, inactiveNavbarDropdownCreatorTypeName, inactiveNavbarDropdownDivisionCreatorTypeName, incentiveActions, incentiveNavActionHandlerCreatorTypeName, incentiveNavSearchQueryCreatorTypeName, incentiveNavToggleCreatorTypeName, incentiveUpdateCurrentPageCreatorTypeName } from "./slice";

import { API_PATH } from "@app/common/constants";
import { NetworkService } from "@app/services";
import { addTotalInSegmentList } from "../helper";
import { salesTransferAction } from "../pages/sales-transfer/redux/slice";

export function* incentiveNavSearchQueryWatcher(action: any) {
  yield takeLatest(
    incentiveNavSearchQueryCreatorTypeName,
    incentiveNavSearchQueryWorker,
  );
}

function* incentiveNavSearchQueryWorker(action: any) {
  yield put(incentiveActions.updateNavSearchQuery(action.payload));
}

export function* incentiveNavToggleWatcher(action: any) {
  yield takeLatest(
    incentiveNavToggleCreatorTypeName,
    incentiveNavToggleWorker,
  );
}

function* incentiveNavToggleWorker(action: any) {
  yield put(incentiveActions.updateNavToggle(action.payload))
}

export function* incentiveNavActionHandlerWatcher(action: any) {
  yield takeLatest(
    incentiveNavActionHandlerCreatorTypeName,
    incentiveNavActionHandlerWorker
  )
}

function* incentiveNavActionHandlerWorker (action: any) {
  yield put(incentiveActions.updateModalHandler(action.payload))
}

function * incentiveNavStateListWorker(): any {
  try {
    const url = API_PATH.directoryConfiguration.hoUserState
    const res = yield call(NetworkService.get, url, {}, {})
    yield put(incentiveActions.updateStatesList(res.data))
  } catch (error) {
     
  }
}

export function* incentiveNavStateLisWatcher() {
  yield takeLatest(
    inactiveNavbarDropdownCreatorTypeName,
    incentiveNavStateListWorker
  )
}

export function* incentiveNavDivisionListWatcher() {
  yield takeLatest(
    inactiveNavbarDropdownDivisionCreatorTypeName,
    incentiveNavDivisionListWorker
  )
}

function * incentiveNavDivisionListWorker(action: any): any {
  try {
    const payload = action.payload;
    const url = API_PATH.directoryConfiguration.hoUserDivision
    const res = yield call(NetworkService.get, url, payload, {})
    if (res?.data) yield put(incentiveActions.updateDivisionList(res.data))
  } catch (error) {
    console.log("error ===>", error);
  }
}

export function* incentiveUpdateCurrentPageWatcher(action: any) {
  yield takeLatest(
    incentiveUpdateCurrentPageCreatorTypeName,
    incentiveUpdateCurrentPageWorker,
  );
}

function* incentiveUpdateCurrentPageWorker(action: any) {
  yield put(incentiveActions.updateCurrentPage(action.payload));
}


// incentive distribution Saga
export function* fetchYearlyQuartersWatcher() {
  yield takeLatest(fetchYearlyQuartersTypeName, fetchYearlyQuartersWorker);
}

function* fetchYearlyQuartersWorker(action: any): any  {
  try {
    const url = API_PATH.incentiveConfiguration.getQuarter;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.data) {
      yield put(incentiveActions.setQuarterData(response?.data || []));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchFromHQWatcher() {
  yield takeLatest(fetchFromHQTypeName, fetchFromHQWorker);
}

function* fetchFromHQWorker(action: any): any  {
  try {
    const url = API_PATH.incentiveConfiguration.getHQ;
    const response = yield call(NetworkService.get, url, action.payload, {});
    console.log('action', action, '\n\n', response)
    if (response?.data) {
      yield put(incentiveActions.setFromHQData(response?.data || []));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchToHQWatcher() {
  yield takeLatest(fetchToHQTypeName, fetchToHQWorker);
}

function* fetchToHQWorker(action: any): any  {
  try {
    const url = API_PATH.incentiveConfiguration.getHQ;
    const response = yield call(NetworkService.get, url, action.payload, {});
    console.log('action', action, '\n\n', response)
    if (response?.data) {
      yield put(incentiveActions.setToHQData(response?.data || []));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchSLMWatcher() {
  yield takeLatest(fetchSLMTypename, fetchSLMWorker);
}

function* fetchSLMWorker(action: any): any  {
  try {
    const url = API_PATH.incentiveConfiguration.getSLM;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setSLMData(response?.data))
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchSalesTransferWatcher() {
  yield takeLatest(fetchSalesTransferDataTypename, fetchSalesTransferWorker);
}

function* fetchSalesTransferWorker(action: any): any  {
  try {
    yield put(incentiveActions.setSalesTransferLoading(true))
    const url = API_PATH.incentiveConfiguration.getSalesTransferData;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      const updatedData = {
        ...response.data,
        segmentList: addTotalInSegmentList(response?.data.segmentList),

      }      
      yield put(incentiveActions.setSalesTransferData(updatedData))
    }
  } catch (error) {
    console.log('error ==>', error);
  } finally {
    yield put(incentiveActions.setSalesTransferLoading(false))
  }
}