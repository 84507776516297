import {Box, Button, Grid} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {
  fetchFromHQCreator,
  fetchSLMCreator,
  fetchSalesTransferDataCreator,
  fetchToHQCreator,
  fetchYearlyQuartersCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentiveActions,
} from '../../../redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import Dropdown from '@app/components/dropdown';
import {Styles} from './style';
import {appSelector} from '@app/store/selectors';
import {incentiveSelector} from '../../../redux/selectors';

function FilterHeader() {
  const [division, setDivision] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [quarter, setQuarter] = useState<
    {label: string; id: number} | undefined
  >(undefined);
  const [fromHQ, setFromHQ] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [toHQ, setToHQ] = useState<{label: string; value: number} | undefined>(
    undefined,
  );

  const [slm, setSLM] = useState<{label: string; value: number} | undefined>(
    undefined,
  );

  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const quarterList = useSelector(incentiveSelector.getQuartetList());
  const FromHQList = useSelector(incentiveSelector.getFROMHQList());
  const toHQList = useSelector(incentiveSelector.getToHQList());
  const slmList = useSelector(incentiveSelector.getSLMData());

  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;
  const staffPositionId = userInfo.staffPositionId;

  useEffect(() => {
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
    dispatch(fetchYearlyQuartersCreator());
    dispatch(
      fetchToHQCreator({
        staffPositionId: staffPositionId,
        isToHQ: true,
      }),
    );
  }, []);

  const dispatch = useDispatch();


  const filterHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      if (slm && quarter && fromHQ && toHQ) {
        const dataParam = {
          staffPositionId: slm?.value,
          quarterId: quarter?.id,
          fromHQId: fromHQ?.value,
          toHQId: toHQ?.value,
        };
        dispatch(incentiveActions.setSelectedQuarter(quarter));
        dispatch(fetchSalesTransferDataCreator(dataParam));
      }
    },
    [slm, quarter, fromHQ, toHQ],
  );

  return (
    <div style={Styles.filterHeaderBody}>
      <div style={Styles.filterBox}>
        <Grid container justifyContent={'space-between'} spacing={1}>
          <Grid item xs={2}>
            <Box style={Styles.inputCell}>
              <Dropdown
                onChange={(val: any) => {
                  dispatch(
                    fetchSLMCreator({
                      divisonId: val.value,
                    }),
                  );
                  setDivision(val || undefined);
                }}
                value={division}
                disabled={false}
                dropdownList={divisionList || []}
                placeholder="Select Division"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={Styles.inputCell}>
              <Dropdown
                onChange={(val: any) => {
                  setSLM(val || undefined);
                  dispatch(
                    fetchFromHQCreator({
                      staffPositionId: val.value,
                      isToHQ: false,
                    }),
                  );
                }}
                value={slm}
                dropdownList={slmList || []}
                placeholder="Select SLM"
                disabled={!division}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={Styles.inputCell}>
              <Dropdown
                onChange={(val: any) => {
                  setQuarter(val || undefined);
                }}
                value={quarter}
                dropdownList={quarterList || []}
                placeholder="Quarter"
                disabled={!Boolean(slm) || !Boolean(division)}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={Styles.inputCell}>
              <Dropdown
                onChange={(val: any) => {
                  setFromHQ(val || undefined);
                }}
                value={fromHQ}
                dropdownList={FromHQList || []}
                placeholder="From HQ"
                disabled={!division || !slm}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={Styles.inputCell}>
              <Dropdown
                onChange={(val: any) => {
                  setToHQ(val || undefined);
                }}
                value={toHQ}
                dropdownList={toHQList || []}
                placeholder="To HQ"
                disabled={!division || !slm || !fromHQ}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Button
              data-testid="clickGo-icon"
              variant="contained"
              onClick={filterHandler}
              size="small"
              style={{
                fontFamily: ['Poppins'].join(','),
                fontSize: 12.7,
                padding: '11px',
                // fontWeight: '600',
                cursor: 'pointer',
                // fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'center',
                backgroundColor: '#322b7c',
                color: 'white',
                border: 0,
                borderRadius: 10,
                minWidth: 50,
              }}>
              Go
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default React.memo(FilterHeader);
