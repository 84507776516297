import { createSelector } from "@reduxjs/toolkit";

const unlockRequestData = (state: any) => state.unlockRequestState.unlockRequestData;

const getUnlockRequestData = createSelector(
  [unlockRequestData],
  (_unlockRequestData) => _unlockRequestData
);

export const unlockRequestSelector = {
  getUnlockRequestData: () => getUnlockRequestData,
}