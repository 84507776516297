import {useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {
  BottomNavigation,
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
} from '@mui/material';
import {filterSelector, filterStateActions} from './redux';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {applyFilterCreator} from './redux/filterHandler';
import {trackEvent} from '@app/utils/analytics';
import {
  EVENT_NAME_APPLY_BUTTON,
  EVENT_NAME_CLEAR_ALL,
} from '@app/utils/analytics/constants';
import NotificationsComponent from '../notifications';
import PlanAndMeetFilter from './plan-and-meet';
import {routeSelector} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';

const drawerWidth = 240;
const styles = {
  checkbox: (theme: any) => {
    return {
      color: ` ${theme.palette.primary.main} !important`,
    };
  },
  marginLeft15: {
    marginLeft: 1,
  },
  backgroundWhite: (theme: any) => {
    return {
      backgroundColor: theme.palette.white.main,
    };
  },
  chipFilter: (theme: any) => {
    return {
      color: theme.typography.rightSideBar.color,
      fontWeight: 'bold',
      backgroundColor: theme.palette.white.main,
    };
  },
  chipClearAll: (theme: any) => {
    return {
      // eslint-disable-next-line dot-notation
      color: theme.typography.rightSideBar['color'],
      fontWeight: 'bold',
      backgroundColor: theme.palette.white.main,
    };
  },
  paper: (theme: any) => {
    return {
      width: drawerWidth - 70,
      backgroundColor: theme.palette.white.main,
      position: 'fixed',
      bottom: 20,
      right: 35,
      boxShadow: 'none',
      borderRadius: '4px',
      zIndex: 100,
    };
  },
  paperButton: (theme: any) => {
    return {
      border: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
      width: '150px',
      height: '40px',
      fontSize: 12,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    };
  },
};

export const RightSidebar = () => {
  const dispatch = useDispatch();
  const [filterComponents, setFilterComponent] = useState<any>(<></>);
  const filters = useSelector(filterSelector.getFilterState(), shallowEqual);
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
  );

  const clearAllFilter = () => {
    trackEvent(EVENT_NAME_CLEAR_ALL, {});
    const filtersCopy = JSON.parse(JSON.stringify(filters || {}));
    filtersCopy &&
      Object.keys(filtersCopy).forEach(key => {
        if (filtersCopy && filtersCopy[key]) {
          filtersCopy[key].completeApplied = false;
          if (filtersCopy[key] && filtersCopy[key].options) {
            Object.keys(filtersCopy[key].options).forEach(optionKey => {
              filtersCopy[key].options[optionKey] = false;
              return null;
            });
          }
        }
      });
    dispatch(filterStateActions.setFilterState(filtersCopy));
  };

  const alterOptionFilterAppliedState = (
    state: any,
    parentKey: any,
    childKey: any,
  ) => {
    const filtersCopy = JSON.parse(JSON.stringify(filters || {}));
    if (
      filtersCopy &&
      filtersCopy[parentKey] &&
      filtersCopy[parentKey].completeApplied
    ) {
      filtersCopy[parentKey].completeApplied = false;
    }
    if (filtersCopy[parentKey] && filtersCopy[parentKey].options) {
      typeof filtersCopy[parentKey].options[childKey] === 'boolean' &&
        (filtersCopy[parentKey].options[childKey] = state);
    }
    dispatch(filterStateActions.setFilterState(filtersCopy));
  };

  const alterAllAppliedState = (state: any, key: any) => {
    const filtersCopy = JSON.parse(JSON.stringify(filters || {}));
    if (filtersCopy && filtersCopy[key]) {
      filtersCopy[key].completeApplied = state;
      if (filtersCopy[key] && filtersCopy[key].options) {
        if (state) {
          Object.keys(filtersCopy[key].options).forEach(optionKey => {
            filtersCopy[key].options[optionKey] = true;
            return null;
          });
        } else {
          Object.keys(filtersCopy[key].options).forEach(optionKey => {
            filtersCopy[key].options[optionKey] = false;
            return null;
          });
        }
      }
      dispatch(filterStateActions.setFilterState(filtersCopy));
    }
  };

  const SecondaryListItems = function ({
    filter,
    parentKeyName,
    indexNumber,
  }: {
    filter: any;
    parentKeyName: any;
    indexNumber: any;
  }) {
    return (
      <>
        {filter ? (
          <Box marginBottom={'20px'} marginLeft={'10px'}>
            <ListItem
              data-testid={`set-filter-Collapsed-state-${indexNumber}`}
              disablePadding
              button
              key={parentKeyName}
              onClick={() => {
                dispatch(
                  filterStateActions.setFilterCollapsedState({
                    key: parentKeyName,
                    value: !filter.collapsed,
                  }),
                );
              }}>
              <ListItemIcon>
                <Checkbox
                  sx={styles.checkbox}
                  checked={filter?.completeApplied}
                  data-testid={`alter-all-applied-state-${indexNumber}`}
                  onClick={() => {
                    alterAllAppliedState(
                      !filter?.completeApplied,
                      parentKeyName,
                    );
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: '#1c1939',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  textAlign: 'left',
                  fontSize: 12,
                }}
                primary={parentKeyName}
              />

              <Box paddingRight={'15px'}>
                {!filter.collapsed && <ExpandLess />}
                {filter.collapsed && <ExpandMore />}
              </Box>
            </ListItem>

            <Collapse in={!filter.collapsed} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding={true}
                sx={styles.marginLeft15}>
                {Object.keys(filter?.options || {})?.map(
                  (childKey: any, index: number) => {
                    return (
                      <ListItem disablePadding button key={childKey}>
                        <ListItemIcon>
                          <Checkbox
                            data-testid={`alter-option-filter-applied-state-${index}`}
                            sx={styles.checkbox}
                            checked={filter?.options[childKey]}
                            onClick={() => {
                              alterOptionFilterAppliedState(
                                !filter?.options[childKey],
                                parentKeyName,
                                childKey,
                              );
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={childKey} />
                      </ListItem>
                    );
                  },
                )}
              </List>
            </Collapse>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getFilterLayout = (filtersInput: any) => {
    const filterItems: any = [];
    Object.keys(filtersInput).forEach((key, index) => {
      filterItems.push(
        <SecondaryListItems
          filter={filtersInput[key]}
          parentKeyName={key}
          indexNumber={index}
        />,
      );
    });
    return <Box paddingBottom={'30px'}>{filterItems}</Box>;
  };

  useEffect(() => {
    filters && Object.keys(filters).length > 0
      ? setFilterComponent(getFilterLayout(filters))
      : setFilterComponent(<></>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const renderFilters = () => {
    switch (navbarComponentNameSelector) {
      case navbarComponentName.planAndMeet:
      case navbarComponentName.dcrUnlockRequest:
        return <PlanAndMeetFilter />;

      case navbarComponentName.approvalWorkflow:
      case navbarComponentName.positionManagement:
        return <></>;

      default:
        break;
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: '#f5f8f9',
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          border: 0,
        },
      }}
      variant="permanent"
      anchor="right">
      <NotificationsComponent />

      {navbarComponentNameSelector === navbarComponentName.planAndMeet ||
      navbarComponentNameSelector === navbarComponentName.dcrUnlockRequest ||
      navbarComponentNameSelector === navbarComponentName.positionManagement ||
      navbarComponentNameSelector === navbarComponentName.approvalWorkflow ? (
        <>{renderFilters()}</>
      ) : (
        <Box
          margin={'20px'}
          borderRadius={'20px'}
          sx={styles.backgroundWhite}
          className={`hide-scrollbar`}
          height={'100vh'}
          overflow={'scroll'}>
          {filters && Object.keys(filters).length > 0 && (
            <>
              <Box
                display={'flex'}
                justifyContent="space-between"
                marginBottom={'10px'}
                padding={'10px'}>
                <Chip label="Filter" size="small" sx={styles.chipFilter} />
                <Chip
                  data-testid="clear-filter"
                  label="Clear All"
                  size="small"
                  clickable
                  onClick={clearAllFilter}
                  sx={styles.chipClearAll}
                />
              </Box>

              {filterComponents || <></>}

              <Paper sx={styles.paper} className={`height-100`} elevation={3}>
                <BottomNavigation showLabels value={0}>
                  <Button
                    data-testid="apply-filter"
                    variant="contained"
                    onClick={() => {
                      trackEvent(EVENT_NAME_APPLY_BUTTON, {});
                      dispatch(applyFilterCreator(null));
                    }}
                    sx={styles.paperButton}>
                    Apply
                  </Button>
                </BottomNavigation>
              </Paper>
            </>
          )}
        </Box>
      )}
    </Drawer>
  );
};
