import React, { useCallback } from 'react'

import { TextField } from '@mui/material'

interface Props {
  value: string,
  onChange: Function,
  placeholder: string
  size?: string,
  disable?: boolean 
}

function CustomInput(props: Props) {

  const handleChanges = useCallback((e) => {
    props.onChange(e.target.value)
  }, [])

  return (
    // <OutlinedInput placeholder={props.placeholder} value={props.value} onChange={(e: any) => handleChanges(e)} style={{width: '100%'}} />
    <TextField
      sx={{ width: 450 }}
      fullWidth
      placeholder={props.placeholder}
      required
      disabled={props.disable ? props.disable : false}
      type="text"
      style={{ width: '100%' }}
      value={props.value} onChange={(e: any) => handleChanges(e)}
      inputProps={{
        maxlength: 30,
        style: {
          paddingTop: props.size === "small" ? 4 : 12,
          paddingBottom: props.size === "small" ? 4 : 12,
          paddingLeft: props.size === "small" ? 10 : 20,
          paddingRight: props.size === "small" ? 10 : 20
        }
      }}

    />
  )
}

export default React.memo(CustomInput)