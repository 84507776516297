import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { SearchBar } from '@app/components/widgets/searchBar';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import { useStyles } from './styles';
import { Label } from '@app/components/elements/Label';
import { Button } from '@mui/material';
import excelLogo from '@app/assets/images/excelLogo.png';
import {
  dcrDownloadExcelCreator,
  dcrUnlockRequestSelector,
  dcrUnlockRequestStateActions,
  fetchDcrUnlockRequestListCreator,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';

const status = ['Pending', 'Approved'];

export const DcrUnlockNavBar = () => {
  const dispatch = useDispatch();
  const [statusSelected, setStatusSelected] = useState({
    checked: false,
    status: status,
  });
  const [searchError, setSearchError] = useState(false);

  const dcrnavbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles(statusSelected);
  const dcrsearchText = useSelector(
    dcrUnlockRequestSelector.getDcrUnlockRequestSearchText(),
  );
  const gridOptions = useSelector(
    dcrUnlockRequestSelector.getDcrUnlockRequestGridOption(),
  );

  const fetchListData = () => {
    dispatch(
      dcrUnlockRequestStateActions.setDcrUnlockRequestGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );
    dispatch(fetchDcrUnlockRequestListCreator());
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(dcrUnlockRequestStateActions.setDcrUnlockRequestSearchText(value));
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const handleStatus = (event: any) => {
    setStatusSelected({
      ...statusSelected,
      [event.target.name]: event.target.checked,
    });
    dispatch(
      dcrUnlockRequestStateActions.setDcrUnlockRequestApprovedSelected(
        event.target.checked,
      ),
    );
    fetchListData();
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (dcrsearchText?.length >= 3 || dcrsearchText?.length === 0) {
        setSearchError(false);
        fetchListData();
      } else {
        setSearchError(true);
      }
    }
  };

  const onPressdownloadExcel = () => {
    dispatch(dcrDownloadExcelCreator());
  };

  return (
    <div className={classes.dcrContainer}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={dcrnavbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        <div>
          <Button
            onClick={onPressdownloadExcel}
            data-testid="dcr_download_excel">
            <img
              src={excelLogo}
              alt="Logo"
              className={classes.downloadExcelLogo}
            />
            <Label
              title={'Download Excel'}
              variant="h6"
              textColor={'#322b7c'}
              fontWeight={'800'}
              hideToolTip
            />
          </Button>
        </div>
      </div>
      <div className={classes.searchContainer}>
        <SearchBar
          searchText={dcrsearchText}
          handleChange={handleSearchTextChange}
          handleSearch={handleSearchPress}
          showError={searchError}
          placeHolder={"Search by name or employee code"}
          errorMessage={"Please Enter Minimum 3 Characters"}
        />
        <FormControl
          sx={{ mt: 1.2, width: 180, alignSelf: 'end', marginLeft: 5 }}>
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={statusSelected.checked}
            onChange={handleStatus}
            name="checked"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            data-testid={'statusSwitchOption'}
          />
        </FormControl>
      </div>
    </div>
  );
};
