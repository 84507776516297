export const Styles = {
  ModalBody: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 533,
    p: 3,
    backgroundColor: "#ffffff",
    borderRadius: 5,
    overflow: 'auto'
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  errorContainer: {
    left: 30,
    position: "absolute",
    top: 5 
  },
}