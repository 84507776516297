import {DropDownValue, MonthType, SegmentList} from '../types';

export const validateReason = (val: string) => {
  const myRegex = /^([\sa-zA-Z0-9_-]){0,15}$/;
  return myRegex.test(val);
};

export const getFormattedRequest = (data: any) => {
  const response = data;
  let formattedResponse: DropDownValue[] = [];
  response.map((res: string, index: number) => {
    formattedResponse.push({
      label: res,
      value: index,
    });
  });

  return formattedResponse;
};

export const getSegmentTotal = (
  subSegment: Omit<SegmentList, 'subSegment'>[],
  monthType: MonthType,
) => {
  let total = 0;
  subSegment.forEach((segmentData: Omit<SegmentList, 'subSegment'>) => {
    total += Number(segmentData[monthType]);
  });
  return total;
};
function getDataForSubSegment(subSegment: any) {
  let data;
  data = subSegment.map((segmentData: any) => {
    return {
      ...segmentData,
      incentivePercent: Number(segmentData.incentivePercent) * 100,
    };
  });
  return data;
}

export const addTotalInSegmentList = (segmentDta: SegmentList[]) => {
  console.log(segmentDta);
  const updatedData = segmentDta.map((data: SegmentList) => {
    if (Boolean(data.subSegment.length)) {
      const subSegmentData = getDataForSubSegment(data.subSegment);
      return {
        ...data,
        month1: getSegmentTotal(data.subSegment, MonthType.month1),
        month2: getSegmentTotal(data.subSegment, MonthType.month2),
        month3: getSegmentTotal(data.subSegment, MonthType.month3),
        incentiveAmount: getSegmentTotal(data.subSegment, MonthType.incentive),
        subSegment: subSegmentData,
      };
    }
    return {
      ...data,
      incentivePercent: Number(data.incentivePercent) * 100,
    };
  });

  return updatedData.map((data: SegmentList) => {
    return {...data, total: Number(data.month1) + Number(data.month2) + Number(data.month3),};
  });
};

export const getGrandTotal = (
  subSegment: SegmentList[],
  monthType: MonthType,
) => {
  let total = 0;
  subSegment?.map((segmentData: SegmentList) => {
    total += Number(segmentData[monthType]);
  });
  return total;
};