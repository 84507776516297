import { API_PATH } from "@app/common/constants";
import { moduleSpecificUtilitiesActions, moduleSpecificUtilitiesPageLists } from "@app/screens/module-specific-utilities/redux";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  digitalTwinPartyMasterActions,
  fetchPartiesTypeName,
} from "./slice";
/**
 * Fetch Metric Category List watcher
 */

export function* fetchPartyMastersWatcher() {
  yield takeLatest(fetchPartiesTypeName, fetchPartyMasterWorker);
}

/**
 * Fetch Metric Category List Worker
 */

function* fetchPartyMasterWorker(action: any): any {
  const { name, pageIndex, pageSize } = action.payload;
  yield put(
    moduleSpecificUtilitiesActions.updateLoading(API_PATH.digitalTwinPartyMaster.fetchPartyMasters)
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.digitalTwinPartyMaster.fetchPartyMasters,
      {
        name,
        pageIndex,
        pageSize,
      },
      {}
    );
    if (pageIndex === 1) {
      yield put(digitalTwinPartyMasterActions.setPartyMasters(response.data));
      yield put(
        moduleSpecificUtilitiesActions.updateTotalRecords({
          listing: moduleSpecificUtilitiesPageLists.digitalTwinPartyMaster,
          count: response?.data[0]?.totalCount || 0,
          pageIndex,
        })
      );
    } else {
      yield put(digitalTwinPartyMasterActions.updatePartyMasters(response.data));
      yield put(
        moduleSpecificUtilitiesActions.updateTotalRecords({
          listing: moduleSpecificUtilitiesPageLists.digitalTwinPartyMaster,
          pageIndex,
        })
      );
    }
    yield put(
      moduleSpecificUtilitiesActions.updateLoading(null)
    );
  } catch (error) {
    yield put(
      moduleSpecificUtilitiesActions.updateLoading(null)
    );
    ToasterService.showToaster(
      "Something went wrong while fetching data!",
      ToastType.ERROR
    );
  }
}
