import {
  actions,
  incentiveActions,
  incentiveNavActionHandlerCreator,
  incentiveUpdateCurrentPageCreator,
} from '../../redux';
import {
  inactiveNavbarDropdownCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentivePageLists,
} from '../../redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {unlockRequestCreator, unlockRequestSelector} from './redux';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {API_PATH} from '@app/common/constants';
import AddRequestModal from '@app/screens/incentive-configuration/components/AddRequestModal';
import FilterHeader from '../../components/FilterHeader/FilterHeader';
import {If} from '@app/components/conditional';
import {ModalExistingDataType} from '../../types';
import TableList from '../../components/TableList';
import {appSelector} from '@app/store/selectors';
import {getUnlockRequestTypesCreator} from './redux/slice';
import {incentiveSelector} from '../../redux/selectors';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {routeStateActions} from '@app/router/redux';

const columns = [
  // 'Unlock Date',
  'div',
  'state',
  'Unlocked till date',
  'Reason',
  'request For',
  'Status',
];

const UnlockRequest = () => {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [selectedCellData, setSelectedCellData] = useState<
    ModalExistingDataType | undefined
  >(undefined);
  const [selectedFilter, setSelectedFilter] = useState({
    unlockTillDate: null,
    stateId: null,
    divisionId: null,
    requestId: null,
  });

  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const reFetchData = useSelector(
    incentiveSelector.getReFetchData(),
    shallowEqual,
  );
  const unlockRequestData = useSelector(
    unlockRequestSelector.getUnlockRequestData(),
    shallowEqual,
  );
  const modal: any = useSelector(
    incentiveSelector.getincentiveModal(),
    shallowEqual,
  );
  const loading: any = useSelector(
    incentiveSelector.getLoading(),
    shallowEqual,
  );

  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;

  const clearFilterData = useSelector(incentiveSelector.clearFilterData());

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.incentiveConfiguration,
      ),
    );
    dispatch(getUnlockRequestTypesCreator('a'));
    dispatch(inactiveNavbarDropdownCreator('a'));
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
    dispatch(
      incentiveActions.updateNavData({
        title: 'Unlock Request',
        status: {show: true, value: true},
        action: {title: 'Add', type: actions.addUnlockRequest},
      }),
    );
    dispatch(
      incentiveUpdateCurrentPageCreator(incentivePageLists.unlockRequest),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(incentiveActions.clearState());
    };
  }, []);

  const fetchUnlockRequest = useCallback(
    (index: number) => {
      const requestParams: any = {
        pageIndex: index,
        isActive: isActive === 1,
        ...selectedFilter,
      };
      dispatch(unlockRequestCreator(requestParams));
    },
    [isActive, selectedFilter],
  );

  useEffect(() => {
    setSelectedFilter({
      unlockTillDate: null,
      stateId: null,
      divisionId: null,
      requestId: null,
    });
    fetchUnlockRequest(1);
  }, [isActive]);

  useEffect(() => {
    if (reFetchData) {
      fetchUnlockRequest(1);
      dispatch(incentiveActions.setRefetchData(false));
    }
  }, [reFetchData]);

  useEffect(() => {
    if (clearFilterData) {
      clearFilters();
    }
  }, [clearFilterData]);

  const groupEditHandler = (data: any) => {
    const selectedData: ModalExistingDataType = {
      id: data.id,
      unlockTillDate: new Date(data.unlockTillDate),
      division: {
        value: data.divisionId,
        label: data?.divisionName,
      },
      state: {
        value: data.stateId,
        label: data?.stateName,
      },
      request: {
        value: 0,
        label: data.requestedFor,
      },
      isActive: data?.isActive,
      reason: data.reason,
    };
    setSelectedCellData(selectedData);
    setIsEditable(true);
  };

  const cancelAddUnlockRequestModal = () => {
    setIsEditable(false);
    dispatch(incentiveNavActionHandlerCreator({show: false, type: null}));
  };

  const showAddModal = useMemo(() => {
    setSelectedCellData(undefined);
    setIsEditable(false);
    return modal?.show && modal?.type === actions.addUnlockRequest;
  }, [modal]);

  const isRequestFetching = useMemo(
    () =>
      loading === API_PATH.incentiveConfiguration.fetchUnlockRequest &&
      (!unlockRequestData || unlockRequestData.length === 0),
    [loading, unlockRequestData],
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.incentiveConfiguration.fetchUnlockRequest,
    [loading],
  );

  const isEmpty = useMemo(
    () =>
      !isRequestFetching &&
      (!unlockRequestData || unlockRequestData.length === 0),
    [isRequestFetching, unlockRequestData],
  );

  const onLoadMoreHandler = (index: number) => {
    fetchUnlockRequest(index);
  };

  const addFilters = (data: any) => {
    setSelectedFilter(data);
  };

  const clearFilters = () => {
    setSelectedFilter({
      unlockTillDate: null,
      stateId: null,
      divisionId: null,
      requestId: null,
    });
  };

  return (
    <>
      <FilterHeader
        changeFilter={(data: any) => {
          addFilters(data);
        }}
        clearFilter={() => clearFilters()}
      />
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
        bodyData={unlockRequestData}
        editAction={groupEditHandler}
      />
      <If condition={showAddModal || isEditable}>
        <AddRequestModal
          editMode={isEditable}
          title={`${isEditable ? 'Edit' : 'Add'} Unlock Request`}
          open={showAddModal || isEditable}
          onClose={cancelAddUnlockRequestModal}
          selectedData={selectedCellData}
        />
      </If>
    </>
  );
};

export default UnlockRequest;
