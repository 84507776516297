import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {Button, Checkbox, Typography} from '@mui/material';
import {DropDownValue, ModalExistingDataType} from '../../types';
import {NetworkService, ToasterService} from '@app/services';
import React, {useCallback, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/system';
import CustomInput from '@app/components/custom-input';
import DatePickerComponent from '@app/components/date-picker/DatePickerComponent';
import Dropdown from '@app/components/dropdown';
import If from '@app/components/If';
import Popup from '@app/components/popup';
import {Styles} from './styles';
import {ToastType} from '@app/services/toaster';
import {appSelector} from '@app/store/selectors';
import dayjs from 'dayjs';
import {incentiveActions} from '../../redux';
import {incentiveSelector} from '../../redux/selectors';
import {validateReason} from '../../helper';

interface Props {
  editMode: boolean;
  title?: string;
  open: boolean;
  selectedData?: ModalExistingDataType;
  onClose: () => void;
}

const conditionToRender = (condition:any, param1:any, param2:any) => {
  if (condition) {
    return param1;
  }
  return param2;
};

const rederORCondition = (param1: any, param2: any) => {
  if (param1 != null && param1 !== undefined) {
    return param1;
  }
  return param2;
};

function AddRequestModal(props: Props) {
  const dispatch = useDispatch();
  const {open, onClose} = props;

  const stateList = useSelector(incentiveSelector.getStateList());
  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const requestList = useSelector(incentiveSelector.getRequestList());

  const selectedState = props?.selectedData?.state;
  const selectedDate = rederORCondition(props?.selectedData?.unlockTillDate, null);
  const selectedReason = rederORCondition(props?.selectedData?.reason, '');
  const selectedDivision = props?.selectedData?.division;
  const selectedRequest = props?.selectedData?.request;
  const isSelectedActive = rederORCondition(props?.selectedData?.isActive, true);


  const [date, setDate] = useState<Date | null>(selectedDate);
  const [reason, setReason] = useState<string>(selectedReason);
  const [state, setState] = useState<DropDownValue | undefined>(selectedState);
  const [division, setDivision] = useState<DropDownValue | undefined>(
    selectedDivision,
  );
  const [request, setRequestFor] = useState<DropDownValue | undefined>(
    selectedRequest,
  );
  const [editReasonMode, setEditReasonMode] = useState<boolean>(false);
  const [isRequestActive, setIsRequestActive] = useState<boolean>(
    Boolean(isSelectedActive),
  );
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const addingResponse = useSelector(
    incentiveSelector.addingResponse(),
    shallowEqual,
  );

  const adminId = userInfo.userId;

  const onDateChange = useCallback((val: Date) => {
    setErrorMessage('');
    setIsSubmitted(false);
    setShowErrorMessage(false);
    setDate(new Date(val));
  }, []);

  const reasonChange = useCallback(
    (val: string) => {
      setErrorMessage('');
      setShowErrorMessage(false);
      setIsSubmitted(false);
      if (validateReason(val)) setReason(val);
    },
    [validateReason],
  );

  const onDivisionChange = useCallback((val: any) => {
    setErrorMessage('');
    setShowErrorMessage(false);
    setIsSubmitted(false);
    setDivision(val);
  }, []);

  const onStateChange = useCallback((val: any) => {
    setErrorMessage('');
    setShowErrorMessage(false);
    setIsSubmitted(false);
    setState(val);
  }, []);

  const onReasonChange = useCallback((val: any) => {
    setErrorMessage('');
    setShowErrorMessage(false);
    setEditReasonMode(true);
    setIsSubmitted(false);
    reasonChange(val);
  }, []);

  const onRequestChange = useCallback((val: any) => {
    setErrorMessage('');
    setShowErrorMessage(false);
    setIsSubmitted(false);
    setRequestFor(val);
  }, []);

  const getDisabledStatus = useCallback(() => {
    let disable = true;
    if (
      date === null ||
      !Boolean(state) ||
      !Boolean(division) ||
      !Boolean(request)
    ) {
      setErrorMessage('Please fill all required fields');
    } else if (reason.length < 3) {
      setErrorMessage('Reason should be more than 3 characters');
    } else {
      disable = false;
    }
    return disable || addingResponse;
  }, [date, reason, state, division, request]);

  const resetStateData = useCallback(() => {
    setDate(null);
    setReason('');
    setState(undefined);
    setDivision(undefined);
    setRequestFor(undefined);
    setShowErrorMessage(false);
  }, []);

  const responseHandler = (response: any) => {
    let toastInfo = {
      success: TOAST_MESSAGES.SUCCESSFULLY_ADD,
      error: TOAST_MESSAGES.UNSUCCESSFULL_ADD,
      successType: ToastType.SUCCESS,
      errorType: ToastType.ERROR,
    };
    if (props.editMode) {
      toastInfo = {
        ...toastInfo,
        success: TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
        error: TOAST_MESSAGES.UNSUCCESSFULL_UPDATE,
      };
    }
    if (response.status === 200) {
      ToasterService.showToaster(toastInfo.success, toastInfo.successType);
      setErrorMessage('');
      dispatch(incentiveActions.setRefetchData(true));
      closeModal();
    } else {
      ToasterService.showToaster(toastInfo.error, toastInfo.errorType);
      dispatch(incentiveActions.setRefetchData(false));
      setErrorMessage(response.data.description);
    }
    setIsSubmitted(false);
  };


  const updateRequestHandler = async () => {
    let dataToSend = {
      id: rederORCondition(props.selectedData?.id, 0),
      divisionId: conditionToRender(division, division?.value, selectedDivision?.value),
      stateId: conditionToRender(state, state?.value, selectedState?.value),
      requestedFor: conditionToRender(request, request?.label, selectedRequest?.label),
      reason: reason || editReasonMode ? reason : selectedReason,
      isActive: conditionToRender(isRequestActive, 1, 0),
      unlockTillDate:
      rederORCondition(dayjs(date).format(), dayjs(selectedDate as Date).format()),
    };
    const isDisabled = getDisabledStatus();
    setShowErrorMessage(isDisabled);
    if (isDisabled) return;
    if (props.editMode) {
      try {
        const response = await NetworkService.post(
          API_PATH.incentiveConfiguration.addUnlockRequest,
          {...dataToSend, modifiedBy: adminId},
          {},
        );
        responseHandler(response);
      } catch (err: any) {
        setErrorMessage(err?.message);
      }
    } else {
      try {
        const response = await NetworkService.post(
          API_PATH.incentiveConfiguration.addUnlockRequest,
          {...dataToSend, createdBy: adminId},
          {},
        );
        dispatch(incentiveActions.toggleClearFilter(true));
        responseHandler(response);
      } catch (error: any) {
        setErrorMessage(error?.message);
      }
    }
    setIsSubmitted(false);
  };

  const resetData = useCallback(() => {
    const notEditMode = !props.editMode;
    const notselectedData = !props.selectedData;
    if (rederORCondition(notEditMode, notselectedData)) {
      resetStateData();
      return;
    }
    setEditReasonMode(false);
    setDate(selectedDate as Date);
    setReason(rederORCondition(selectedReason, ''));
    setState(selectedState);
    setDivision(selectedDivision);
    setRequestFor(selectedRequest);
  }, []);

  const closeModal = () => {
    resetData();
    onClose();
  };

  const saveRequest = () => {
    if (isSubmitted) {
      return;
    }
    setIsSubmitted(true);
    updateRequestHandler();
  };

  const onDataChange = (val: any) => {
    const checkEditModeCondition= conditionToRender(props.editMode && selectedDate, onDateChange(selectedDate),setDate(null))
    conditionToRender(val, onDateChange(val),checkEditModeCondition)
  };
  
  return (
    <Popup
      open={open}
      showErrorMessage= {rederORCondition(showErrorMessage, Boolean(errorMessage.length))}
      error={errorMessage}
      onClose={closeModal}
      cancelPopup={closeModal}
      title={props.title}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        }}>
        <Box style={Styles.inputCell} data-testid={'add-select-division'}>
          <Typography style={Styles.labelTypography}>
            Select Division
            <sup>*</sup>
          </Typography>
          <Dropdown
            onChange={(val: any) => {
              onDivisionChange(val);
            }}
            value = {rederORCondition(division, selectedDivision)}
            dropdownList= {rederORCondition(divisionList, [])}
            placeholder="Select Division"
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'add-select-state'}>
          <Typography style={Styles.labelTypography}>
            Select State<sup>*</sup>
          </Typography>
          <Dropdown
            onChange={(val: any) => {
              onStateChange(val);
            }}
            value= {rederORCondition(state, selectedState)}
            dropdownList={rederORCondition(stateList, [])}
            placeholder="Select State"
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'add-unlock-date'}>
          <Typography style={Styles.labelTypography}>
            Unlocked Till Date<sup>*</sup>
          </Typography>
          <DatePickerComponent
            onError={() => setDate(null)}
            value= {rederORCondition(date, (selectedDate as Date))}
            disablePast={true}
            placeholder="Select Date"
            onChange={(val: Date) => {
              onDataChange(val);
            }}
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'add-add-reason'}>
          <Typography style={Styles.labelTypography}>
            Reason<sup>*</sup>
          </Typography>
          <CustomInput
            value={ 
              rederORCondition(
              rederORCondition(reason, 
                conditionToRender(editReasonMode,reason,selectedReason)
                ),'')}
            onChange={(val: string) => {
              onReasonChange(val);
            }}
            placeholder="Enter Reason"
          />
        </Box>
        <Box style={Styles.inputCell} data-testid={'add-requestfor'}>
          <Typography style={Styles.labelTypography}>
            Request For <sup>*</sup>
          </Typography>
           <Dropdown
            onChange={(val: any) => {
              onRequestChange(val);
            }}
            value={rederORCondition(request, selectedRequest)}
            dropdownList={requestList}
            placeholder="Request For"
          />
        </Box>
        <If condition={props.editMode}>
          <Typography
            style={{...Styles.labelTypography, marginBottom: 5, marginLeft: 3}}>
            Is Active
          </Typography>
          <Box style={{justifyContent: 'flex-start'}} data-testid={'add-request-active-check'}>
            <Checkbox
              // {...label}
              checked={isRequestActive}
              onChange={() => {
                setShowErrorMessage(false);
                setIsRequestActive(!isRequestActive);
              }}
              sx={{
                color: '#322b7c',
                '&.Mui-checked': {
                  color: '#322b7c',
                },
              }}
            />
          </Box>
        </If>
      </Box>

      <Box style={Styles.actionButtonContainer}>
        <Box style={Styles.actionButtonContainer}>
          <Button
            data-testid = "add-reset-request"
            variant="outlined"
            onClick={resetData}
            style={{marginRight: 10}}>
            Reset
          </Button>
          <Button
            data-testid = "add-save-request"
            variant="contained"
            onClick={saveRequest}>
            Save
          </Button>
        </Box>
      </Box>
    </Popup>
  );
}

export default React.memo(AddRequestModal);
