import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogBox(props: {
  open: boolean;
  handleClose: any;
  header: string;
  body: string;
}) {

  const {open, handleClose, body, header} = props;
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: 15}}>
        <DialogTitle>{header}</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {body}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default React.memo(DialogBox);