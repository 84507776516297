import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
  incentiveNavActionHandlerCreator,
  incentiveNavSearchQueryCreator,
  incentiveNavToggleCreator,
} from '@app/screens/incentive-configuration/redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Search } from "@mui/icons-material";
import { incentiveSelector } from '@app/screens/incentive-configuration/redux/selectors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  search: {
    '& input': {
      padding: '12px 30px 12px 0',
      color: theme.palette.primary.main,
      '::placeholder': theme.palette.primary.main,
    },
  },
}));

const IncentiveNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const title = useSelector(incentiveSelector.getNavTitle());
  const action = useSelector(incentiveSelector.getNavAction());
  const search = useSelector(incentiveSelector.getNavSearch());
  const status = useSelector(incentiveSelector.getStatus, shallowEqual);
  const totalCount = useSelector(incentiveSelector.totalRecords, shallowEqual);
  const showToggle = useSelector(
    incentiveSelector.getShowNavToggle(),
    shallowEqual,
  );

  const onSearchHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(incentiveNavSearchQueryCreator(event.target.value));
  };

  const onToggleHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(incentiveNavToggleCreator(event.target.checked));
  };

  const onActionHandler = () => {
    dispatch(incentiveNavActionHandlerCreator({ show: true, type: action.type }));
  };

  return (
    <Grid container justifyContent={'space-between'}  sx={{height: 100}}>
      <Grid item xs={8}>
        <Box
          sx={{
            mt: 1,
            minWidth: 200,
          }}>
          <Typography variant="title" color={'primary.main'}>
            {title} {Boolean(totalCount) && `( ${totalCount} )`}
          </Typography>
        </Box>
        {search ? (
          <TextField
            variant="outlined"
            placeholder={'Search here'}
            className={classes.search}
            type="search"
            fullWidth
            value={search.query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={onSearchHandler}
          />
        ) : null}
      </Grid>
      <Grid
        item
        display={'flex'}
        direction="column"
        justifyContent={'space-between'}
        alignContent={'flex-end'}>
        <Grid
          item
          display={'flex'}
          direction="row"
          justifyContent={'space-between'}>

          <Grid container justifyContent={"center"} alignItems={"center"}>
            {action ? (<Grid item>
              <Button
                variant="contained"
                onClick={onActionHandler}
              >
                {action.title}
              </Button>
            </Grid>) : null}
          </Grid>
        </Grid>

        {showToggle ? (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mt: 3,
            }}
            alignItems="center"
            alignSelf={'flex-end'}>
            <Typography variant="switch">Expired</Typography>
            <Switch
              sx={{
                '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(16px)',
                },
                '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                {
                  opacity: 1,
                },
              }}
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
              checked={status == 1}
              onChange={onToggleHandler}
            />
            <Typography variant="switch">Active</Typography>
          </Stack>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default React.memo(IncentiveNavBar);
